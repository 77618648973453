<template>
  <div class="viewContainer">
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8" elevation="0">
        <div class="content-container">
          <!-- <v-tabs 
            v-model="tab"
            :color="($store.state.app.dark) ? 'white' : 'black'"
            class="content-tabs mb-8"
            background-color="transparent"
            fixed-tabs
            show-arrows
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab class="no-caps" :href="'#general'" :ripple="false">
              {{ $t('general') }}
            </v-tab>
            <v-tab class="no-caps" :href="'#localization'" :ripple="false">
              {{ $t('localization') }}
            </v-tab>
          </v-tabs> -->
          <v-card v-if="loading_main" flat tile class="text-center">
            <v-progress-circular
              :size="32"
              color="primary"
              indeterminate
              class="ma-12"
            ></v-progress-circular>
          </v-card>
          <v-card v-if="!loading_main" flat tile>
            <ValidationObserver ref="form1" v-slot="{ invalid }">
              <v-form 
                :model="form1" 
                id="form1"
                lazy-validation
                @submit.prevent="submitForm('form1')"
                autocomplete="off"
                method="post"
                accept-charset="UTF-8" 
                enctype="multipart/form-data"
              >
                <v-alert
                  :value="form1.hasError && !form1.success"
                  text
                  type="error"
                  class="mb-4"
                >
                  {{ form1.error }}
                </v-alert>
                <v-alert
                  :value="form1.success"
                  text
                  type="success"
                  class="mb-4"
                >
                  {{ $t('update_success') }}
                </v-alert>
                <v-tabs-items v-model="tab" :touchless="false">
                  <v-tab-item :transition="false" :reverse-transition="false"  value="general">
                    <v-card flat tile>
                      <div class="mb-4">
                        <v-label class="input-label mb-2">{{ $t('cover') }}<small class='ml-2'>(1280 x 800px)</small></v-label>
                        <v-hover>
                          <template v-slot:default="{ hover }">
                            <v-avatar
                              height="250"
                              width="100%"
                              class="input-upload">
                              <v-img
                                :src="(form1.cover_media_url == null) ? 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=' : form1.cover_media_url"
                                contain 
                                :style="{'width': '100%'}"
                              >
                                <v-fade-transition>
                                  <v-overlay
                                    v-if="hover"
                                    absolute
                                  >
                                    <!-- <v-btn-toggle rounded> -->
                                      <v-btn @click="pickFile('cover')" small fab color="primary" rounded><v-icon size="14">mdi-upload</v-icon></v-btn>
                                      <v-btn v-if="form1.cover_media_file && form1.cover_media_file_editor" @click="EditImage" class="ml-2" small fab color="warning"><v-icon size="14">mdi-pencil</v-icon></v-btn>
                                      <v-btn v-if="showDeleteCover" @click="$refs.fileupload.value=null; form1.cover_media_url = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA9JREFUeNpiuHbtGkCAAQAFCAKDZcGh3gAAAABJRU5ErkJggg=='; form1.cover_media_changed = true; showDeleteCover = false" class="ml-2" small fab color="error"><v-icon size="14">mdi-close</v-icon></v-btn>
                                    <!-- </v-btn-toggle> -->
                                  </v-overlay>
                                </v-fade-transition>
                              </v-img>
                            </v-avatar>
                          </template>
                        </v-hover>
                        <input
                          type="file"
                          style="display: none"
                          id="cover"
                          name="cover"
                          ref="fileupload"
                          accept="image/*"
                          @change="onFilePicked"
                        >
                      </div>
                      <x-text-field 
                        v-model="form1.name"
                        ref="form1.name"
                        id="form1.name"
                        :label="$t('name')"
                        :name="$t('name')"
                        rules="required|min:1|max:96"
                      />
                      <x-text-field 
                        v-model="form1.short_description"
                        ref="form1.short_description"
                        id="form1.short_description"
                        :label="$t('short_description')"
                        :name="$t('description')"
                        rules="nullable|max:100"
                      />
                    </v-card>
                  </v-tab-item>
                  <!-- <v-tab-item :transition="false" :reverse-transition="false"  value="localization">
                    <v-card flat tile>
                      <x-autocomplete
                        v-model="form1.currency"
                        ref="form1.currency"
                        id="form1.currency"
                        :items="currencies"
                        item-value="0" 
                        item-text="1"
                        :label="$t('currency')"
                        :name="$t('currency')"
                        rules="required"
                        :hint="$t('business_currency_hint')"
                        persistent-hint
                      />
                    </v-card>
                  </v-tab-item> -->
                </v-tabs-items>
                <v-card-actions class="pa-0 mt-4">
                  <v-btn color="primary" block rounded depressed x-large :loading="form1.loading" :disabled="form1.loading || invalid" type="submit" class="no-caps mb-2" style="min-width: 200px">{{ $t('update') }} <v-icon right>mdi-arrow-right</v-icon></v-btn>
                </v-card-actions>
              </v-form>
            </ValidationObserver>
          </v-card>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
// import MenuBusinessSetting from '@/components/ui/MenuBusinessSetting.vue'

export default {
    components: {
      //MenuBusinessSetting,
    },
    data() {
      return {
        tab: 'general',
        tabImg: 'about:blank',
        tabImgHeight: null,
        tabImgDefaultHeight: 780,
        tabImgAspectRation: 1.7778,
        tabImgs: [],
        activeFilePickerId: null,
        showDeleteCover: false,
        currencies: [],
        loading_main: true,
        form1: {
          loading: false,
          currency: '',
          name: '',
          short_description: '',
          cover_media_url: null,
          cover_media_file: null,
          cover_media_changed: false,
          hasError: false,
          error: null,
          success: false
        },
      }
    },
    created () {
      // Tab images
      this.tabImgs = [
        this.$init.config.schemeAndHost + '/img/business-rules-tab.jpg'
      ]

      // Set image depending on the size of the browser window
      if (window.innerWidth > 960) {
        this.tabImg = this.tabImgs[0]
        this.tabImgHeight = this.tabImgDefaultHeight
        this.tabImgAspectRation = 1.7778
      } else {
        this.tabImgHeight = null
        this.tabImgAspectRation = 3
      }
      // Preload
      let images = new Array()
      this.tabImgs.forEach(function(img, i) {
        images[i] = new Image()
        images[i].src = img
      })
      this.loading_main = true
      /*
      this.axios
        .get('/localization/currencies', { params: { locale: this.$i18n.locale }})
        .then(response => {
          this.loading_main = false
          this.currencies = this.$_.toPairs(response.data)

          this.axios
            .get('/business/settings', { params: { locale: this.$i18n.locale }})
            .then(response => {
              let business = response.data.business
              this.form1.cover_media_url = business.cover
              this.showDeleteCover = (this.form1.cover_media_url == null) ? false : true
              this.form1.currency = business.currency
              this.form1.name = business.name
              this.form1.short_description = business.short_description
            })
        })
        */
      this.axios
      .get('/business/settings', { params: { locale: this.$i18n.locale }})
      .then(response => {
        this.loading_main = false
        let business = response.data.business
        this.form1.cover_media_url = business.cover
        this.showDeleteCover = (this.form1.cover_media_url == null) ? false : true
        this.form1.currency = business.currency
        this.form1.name = business.name
        this.form1.short_description = business.short_description
      })
    },
    methods: {
      async submitForm(formName) {
        // Reset form validation
        this.$refs[formName].reset()

        this[formName].success = false
        this[formName].hasError = false
        this[formName].loading = true

        this.updateProfile(formName)
      },
      updateProfile(formName) {
        var app = this[formName]

        let settings = { headers: { 'content-type': 'multipart/form-data' } }

        // Remove image urls
        let formModel = Object.assign({}, this.form1)
        formModel.cover_media_url = null
        formModel.language = this.$i18n.locale

        let formData = new FormData(document.getElementById('form1'))

        for (let field in formModel) {
          let val = (formModel[field] === null) ? '' : formModel[field]
          formData.append(field, val)
        }
        if(this.form1.cover_media_file_editor){
          formData.append('cover', this.form1.cover_media_file_editor) 
        }
        this.axios
          .post('/business/settings', formData, settings)
          .then(response => {
            if (response.data.status === 'success') {
              app.success = true
              this.$nextTick(() => this.$refs[formName].reset())
            }
          })
          .catch(error => {
            app.hasError = true
            if (error.response.data.status === 'error') {
              if (typeof error.response.data.error !== 'undefined') app.error = error.response.data.error
              this.errorMsg = error.response.data.error

              let errors = error.response.data.errors || []

              for (let field in errors) {
                this.$refs[formName + '.' + field].applyResult({
                  errors: errors[field],
                  valid: false,
                  failedRules: {}
                })
              }
            }
          })
          .finally(() => { 
            window.scrollTo(0,0)
            app.loading = false
          })
      },
      pickFile (id) {
        this.activeFilePickerId = id
        document.getElementById(id).click();
      },
      onFilePicked (e) {
        const files = e.target.files
        if(files[0] !== undefined) {
          if(files[0].name.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader ()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.form1[this.activeFilePickerId + '_media_url'] = fr.result
            this.form1[this.activeFilePickerId + '_media_file'] = files[0]
            this.form1[this.activeFilePickerId + '_media_file_editor'] = files[0]
            this.form1[this.activeFilePickerId + '_media_changed'] = true
            this.$root.$ImageEditor(files[0],null)
            .then((confirm) => {
              if (confirm.dest) {
                this.form1[this.activeFilePickerId + '_media_url'] = URL.createObjectURL(confirm.dest);
                this.form1[this.activeFilePickerId + '_media_file_editor'] = confirm.dest
              }
            })
            switch (this.activeFilePickerId) {
              case 'cover':
                this.showDeleteCover = true
                break;
            }
          })
        } else {
          this.form1[this.activeFilePickerId + '_media_file'] = ''
          this.form1[this.activeFilePickerId + '_media_file_editor'] = ''
          this.form1[this.activeFilePickerId + '_media_url'] = ''
          this.form1[this.activeFilePickerId + '_media_changed'] = true
        }
      },
      EditImage(){
        this.$root.$ImageEditor(this.form1[this.activeFilePickerId + '_media_file'],null)
        .then((confirm) => {
          if (confirm.dest) {
            this.form1[this.activeFilePickerId + '_media_url'] = URL.createObjectURL(confirm.dest);
            this.form1[this.activeFilePickerId + '_media_file_editor'] = confirm.dest
          }
        })
      },
    },
    computed: {
      app () {
        return this.$store.getters.app
      }
    }
  }
</script>